import * as bootstrap from "bootstrap";
import { API } from "data/api";
import { post, responseValidator } from "utilities/interface/api";
import "./profile.style.scss";
var $ = require("jquery");
function headerProfileTrigger() {
    $(document).ready(function () {
        var Node = $("#header-profile-menu")[0].outerHTML;
        var news = $('[data-bs-toggle="header-legal-profile"]');
        new bootstrap.Popover(news, {
            html: true,
            trigger: "focus",
            placement: "auto",
            animation: true,
            content: function () {
                return Node;
            },
            container: news,
            offset: "[0, -3]",
        });
        $(document).on("click", "#header-profile-menu .logout", function () {
            post(API.account.logout, {}).then(function (res) {
                if (responseValidator(res))
                    window.location.reload();
            });
        });
    });
}
headerProfileTrigger();
