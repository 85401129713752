// local storage interface
export var authToken = {
    key: "VEKALAPP_AUTH_TOKEN",
    get: function () {
        var data = localStorage.getItem(authToken.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    set: function (value) {
        localStorage.setItem(authToken.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(authToken.key);
    },
};
export var oauthObject = {
    key: "VEKALAPP_OAUTH_OBJECT",
    get: function () {
        var data = localStorage.getItem(oauthObject.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    set: function (value) {
        localStorage.setItem(oauthObject.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(oauthObject.key);
    },
};
export var QAFormData = {
    key: "VEKALAPP_QA_FORM_DATA",
    get: function () {
        var data = localStorage.getItem(QAFormData.key);
        if (data) {
            return JSON.parse(data);
        }
        return {};
    },
    set: function (value) {
        localStorage.setItem(QAFormData.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(QAFormData.key);
    },
};
export var JudicialFileData = {
    key: "VEKALAPP_JUDICIAL_FILE_FORM_DATA",
    get: function () {
        var data = localStorage.getItem(JudicialFileData.key);
        if (data) {
            return JSON.parse(data);
        }
        return {};
    },
    set: function (value) {
        localStorage.setItem(JudicialFileData.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(QAFormData.key);
    },
};
export var contractData = {
    key: "VEKALAPP_CONTRACT_FORM_DATA",
    get: function () {
        var data = localStorage.getItem(contractData.key);
        if (data) {
            return JSON.parse(data);
        }
        return {};
    },
    set: function (value) {
        localStorage.setItem(contractData.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(QAFormData.key);
    },
};
export var telephoneAdvisory = {
    key: "VEKALAPP_TELEPHONE_ADVISORY_FORM_DATA",
    get: function () {
        var data = localStorage.getItem(telephoneAdvisory.key);
        if (data) {
            return JSON.parse(data);
        }
        return {};
    },
    set: function (value) {
        localStorage.setItem(telephoneAdvisory.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(QAFormData.key);
    },
};
