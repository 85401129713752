import "./dropdown.style.scss";
var $ = require("jquery");
var Dropdown = /** @class */ (function () {
    function Dropdown(selector, config) {
        var _this = this;
        this._onOpen = false;
        this._options = [];
        if (config.onChange)
            this._onChange = config.onChange;
        this._elem = $(selector);
        this._mainButton = this._elem.find(".dropdown-body");
        console.log(config.options, this._elem, this._mainButton);
        this._value = this._mainButton.find("> p");
        this._webOptions = this._mainButton.find("> ul");
        this._mobileOptions = this._mainButton.find("> select");
        this._error = this._elem.find(".error-text");
        this.setOptions(config.options);
        this._placeholder = this._elem.attr("placeholder");
        this._mainButton.on("click", function () {
            console.log("===============================");
            _this._onOpen = !_this._onOpen;
            if (_this._onOpen) {
                _this._elem.removeClass("error");
                _this._error.addClass("d-none");
                _this._elem.addClass("open");
            }
            else
                _this._elem.removeClass("open");
        });
        this._mobileOptions.on("change", function (e) {
            _this._options.map(function (i) {
                if (i.payload.toString() === e.target.value)
                    _this.select(i);
            });
        });
        $(document).on("click", function (e) {
            var isButton = _this._mainButton.is(e.target) ||
                _this._mainButton.has(e.target).length > 0;
            var isResult = _this._webOptions.is(e.target) ||
                _this._webOptions.has(e.target).length > 0;
            if (!isButton && !isResult) {
                _this._onOpen = false;
                _this._elem.removeClass("open");
            }
        });
    }
    Dropdown.prototype.setOptions = function (options) {
        var _this = this;
        this._options = options ? options : [];
        this._webOptions.find("> *:not(.d-none)").remove();
        this._mobileOptions.find("> *:not(.d-none)").remove();
        var _loop_1 = function (option) {
            var element1 = this_1._webOptions
                .find(".d-none")
                .clone()
                .removeClass("d-none")
                .attr("value", option.payload)
                .text(option.title);
            element1.on("click", function () {
                _this.select(option);
            });
            element1.insertAfter(this_1._webOptions.find(".d-none"));
            var element2 = this_1._mobileOptions
                .find(".d-none")
                .clone()
                .removeClass("d-none")
                .attr("value", option.payload)
                .text(option.title);
            element2.insertAfter(this_1._mobileOptions.find(".d-none"));
            this_1._mobileOptions.on("change", function () { });
        };
        var this_1 = this;
        for (var _i = 0, _a = this._options; _i < _a.length; _i++) {
            var option = _a[_i];
            _loop_1(option);
        }
    };
    Dropdown.prototype.select = function (option) {
        this._elem.removeClass("error");
        this._error.addClass("d-none");
        this._value.text(option.title);
        this._selected = option;
        this._elem.addClass("fill");
        if (this._onChange)
            this._onChange(option);
    };
    Dropdown.prototype.unSelect = function () {
        this._selected = undefined;
        this._elem.removeClass("fill").removeClass("open");
        this._value.text(this._placeholder);
    };
    Dropdown.prototype.error = function (text) {
        this._elem.addClass("error");
        this._error.removeClass("d-none").text(text);
    };
    Dropdown.prototype.getVal = function () {
        return this._selected;
    };
    return Dropdown;
}());
export default Dropdown;
