import "./popup.style.scss";
import * as $ from "jquery";
var Popup = /** @class */ (function () {
    function Popup(selector, config) {
        var _this = this;
        var _a;
        this.animationTime = 300;
        this.modal = $(selector);
        if (config && config.onClose)
            this.onClose = config.onClose;
        if (config && config.onSheetChange)
            this.onSheetChange = config.onSheetChange;
        if (config && config.title)
            this.modal.find(".popup-container > h4").text(config.title);
        if (config && config.statue === true)
            this.open();
        this.modal.on("mousedown", function (e) {
            if (e.target === _this.modal[0] ||
                e.target === _this.modal.find(".popup-container")[0]) {
                _this.close();
            }
        });
        (_a = this.modal.get(0)) === null || _a === void 0 ? void 0 : _a.addEventListener("touchstart", function (e) {
            if (e.target === _this.modal[0] ||
                e.target === _this.modal.find(".popup-container")[0])
                _this.close();
        });
        this.modal.find(".popup-content .close-button").on("click", function () {
            _this.close();
        });
        this.modal.on("scroll", function (e) {
            _this.onScrollHandler();
        });
    }
    Popup.prototype.open = function () {
        var _this = this;
        this.modal.removeClass("close");
        this.modal.addClass("opening");
        setTimeout(function () {
            _this.modal.addClass("open");
            _this.modal.removeClass("opening");
        }, this.animationTime);
    };
    Popup.prototype.close = function () {
        var _this = this;
        this.modal.scrollTop(0);
        this.modal.addClass("closing");
        setTimeout(function () {
            if (_this.onClose)
                _this.onClose();
            _this.modal.addClass("close");
            _this.modal.removeClass("closing");
        }, this.animationTime);
    };
    Popup.prototype.onScrollHandler = function () {
        var content = this.modal.find(".popup-content");
        if (this.onSheetChange) {
            if (content.offset().top < -30)
                this.onSheetChange("Page");
            else if (content.offset().top > 30)
                this.onSheetChange("Sheet");
        }
    };
    return Popup;
}());
export { Popup };
