import { authToken } from "./storage";
var ENV = require("../../env.json");
function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
function generateHeader(object) {
    if (object === void 0) { object = {}; }
    var header = {};
    // if (authToken.get()) {
    //   header["Authorization"] = "jwt " + authToken.get()?.access;
    // }
    header["X-CSRFToken"] = getCookie("csrftoken");
    for (var _i = 0, _a = Object.keys(object); _i < _a.length; _i++) {
        var key = _a[_i];
        header[key] = object[key];
    }
    return header;
}
export function generateOAuthHeader() {
    var header = {};
    var buff = btoa(unescape(encodeURIComponent(ENV.basicBase64)));
    header["Authorization"] = "Basic " + buff;
    return header;
}
export function del(url, body) {
    var status;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "DELETE",
            body: JSON.stringify(body),
            headers: generateHeader({ "Content-Type": "application/json" }),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function post(url, body) {
    var status;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "POST",
            headers: generateHeader({ "Content-Type": "application/json" }),
            body: JSON.stringify(body),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function form(url, body) {
    var status;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "POST",
            body: body,
            headers: generateHeader(),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function put(url, body) {
    var status;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: generateHeader({ "Content-Type": "application/json" }),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function patch(url, body) {
    var status;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "PATCH",
            body: JSON.stringify(body),
            headers: generateHeader({ "Content-Type": "application/json" }),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function get(url, params) {
    if (params === void 0) { params = {}; }
    var generatedUrl = new URL(url);
    Object.keys(params).forEach(function (key) {
        // if (params[key]) {
        generatedUrl.searchParams.append(key, params[key]);
        // }
    });
    var status;
    return new Promise(function (resolve, reject) {
        fetch(generatedUrl.href, {
            method: "GET",
            headers: generateHeader({ "Content-Type": "application/json" }),
        })
            .then(function (response) {
            status = response.status;
            return response.json();
        })
            .then(function (data) {
            resolve({ data: data, status: status });
        })
            .catch(function (err) {
            resolve({ data: null, status: status });
        });
    });
}
export function responseValidator(response) {
    return response.status >= 200 && response.status < 300;
}
export function upload(URL, formData, onProgress, auth) {
    if (auth === void 0) { auth = true; }
    var abort;
    var promise = new Promise(function (resolve) {
        var _a;
        var request = new XMLHttpRequest();
        abort = request.abort;
        request.onload = function () {
            if (request.readyState == XMLHttpRequest.DONE)
                resolve({
                    status: request.status,
                    data: JSON.parse(request.responseText),
                });
            else
                resolve({ status: request.status, data: null });
        };
        request.upload.addEventListener("progress", function (e) {
            onProgress(e.loaded);
        });
        request.open("post", URL);
        if (auth)
            request.setRequestHeader("Authorization", "jwt " + ((_a = authToken.get()) === null || _a === void 0 ? void 0 : _a.access));
        request.timeout = 45000;
        request.send(formData);
    });
    return { promise: promise, abort: abort };
}
