import { API } from "data/api";
import { toast } from "scripts/toast";
import { form, responseValidator } from "utilities/interface/api";
import InputBase from "../basicInput/basicInput.index";
import "./fileAttachment.style.scss";
var $ = require("jquery");
var FileAttachmentInput = /** @class */ (function () {
    function FileAttachmentInput(selector, config) {
        if (config.onChange)
            this._onChange = config.onChange;
        this._elem = $(selector);
        this._input = this._elem.find(">input");
        this._input.on("change", this.onFileChange.bind(this));
        this._placeholder = this._elem.attr("placeholder");
        this._base = new InputBase(selector + " .vapp-basic-input", {
            onClick: this.onClickHandler.bind(this),
        });
    }
    FileAttachmentInput.prototype.onFileChange = function (e) {
        var _this = this;
        var file = e.target.files[0];
        var data = new FormData();
        data.append("alt", file.name);
        data.append("image", file);
        var interval = setInterval(function () {
            var value = "در حال بارگزاری ";
            var dots = Math.floor(new Date().getTime() / 1000) % 3;
            for (var i = 0; i < dots; i++) {
                value += ".";
            }
            _this._base.setValue(value);
        }, 1000);
        form(API.core.fileAttachment, data).then(function (res) {
            clearInterval(interval);
            if (responseValidator(res) && res.data) {
                _this._base.setValue(file.name);
                _this._onChange(res.data);
            }
            else {
                toast("در بارگزاری خطایی رخ داده است.", "success", {});
                _this._base.setValue(_this._placeholder);
            }
        });
    };
    FileAttachmentInput.prototype.onClickHandler = function () {
        this._input.trigger("click");
    };
    FileAttachmentInput.prototype.unSelect = function () {
        this._selected = undefined;
        this._elem.removeClass("fill").removeClass("open");
    };
    FileAttachmentInput.prototype.error = function (text) {
        this._elem.addClass("error");
        // this._error.removeClass("d-none").text(text);
    };
    FileAttachmentInput.prototype.getVal = function () {
        return this._selected;
    };
    FileAttachmentInput.prototype.setFileName = function (value) {
        this._base.setValue(value);
    };
    return FileAttachmentInput;
}());
export default FileAttachmentInput;
