import { API } from "../../../data/api";
import { irPhoneValidator } from "../../../scripts/validation";
import Button from "../../../utilities/components/button/button.index";
import InputField from "../../../utilities/components/input/input.index";
import { generateOAuthHeader, post, responseValidator, } from "../../../utilities/interface/api";
import { Popup } from "../../../utilities/components/popup/popup.index";
import "./login.style.scss";
import { oauthObject } from "scripts/storage";
var ENV = require("env.json");
var $ = require("jquery");
var popup = new Popup("#vekalapp-header-login-modal");
$("#vekalapp-header-login-modal .close-btn").on("click", function () {
    popup.close();
});
$("#go-to-login").on("click", function () {
    popup.open();
});
export function openLoginModal() {
    popup.open();
}
var sendCode = new Button("#vekalapp-header-login-modal .login-content-steps >.phone-step >button", function () {
    return new Promise(function (resolve) {
        var phone = phoneInput
            .getVal()
            .replace(/[۰-۹]/g, function (d) { return "۰۱۲۳۴۵۶۷۸۹".indexOf(d).toString(); })
            .replace(/[٠-٩]/g, function (d) { return "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString(); });
        post(API.account.sendCode, { phone: phone }).then(function (response) {
            resolve(true);
            if (responseValidator(response)) {
                $("#vekalapp-header-login-modal .login-content-steps >.phone-step").addClass("d-none");
                $("#vekalapp-header-login-modal .login-content-steps >.code-step").removeClass("d-none");
            }
            else {
                alert("خطایی رخ داده است. لطفا مجددا تلاش کنید.");
            }
        });
    });
});
var phoneInput = new InputField("#vekalapp-header-login-modal .login-content-steps >.phone-step .vapp-input", {
    maxLength: 11,
    onChange: function (e) {
        if (irPhoneValidator(e)) {
            sendCode.enable();
        }
        else {
            sendCode.disable();
        }
    },
});
var verifyCode = ["", "", "", ""];
var loginButton = new Button("#vekalapp-header-login-modal .login-content-steps >.code-step >button", function () {
    var phone = phoneInput
        .getVal()
        .replace(/[۰-۹]/g, function (d) { return "۰۱۲۳۴۵۶۷۸۹".indexOf(d).toString(); })
        .replace(/[٠-٩]/g, function (d) { return "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString(); });
    var loginData = new FormData();
    loginData.append("username", phone);
    loginData.append("password", verifyCode.join(""));
    loginData.append("grant_type", "password");
    return new Promise(function (resolve) {
        fetch(API.account.sendVerify, {
            method: "POST",
            body: loginData,
            headers: generateOAuthHeader(),
        }).then(function (res) {
            res.json().then(function (response) {
                var access_token = response.access_token;
                oauthObject.set(response);
                post(API.auth.rotateSession, {
                    access_token: access_token,
                }).then(function () {
                    resolve(true);
                    location.reload();
                });
            });
        });
    });
});
var codeDigit = $("#vekalapp-header-login-modal .login-content-steps >.code-step >.code-row input");
codeDigit.on("keydown", function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).index() > 0 &&
        e.keyCode === 8 &&
        verifyCode[$(this).index()] === "") {
        $(this).prev().val("");
        verifyCode[$(this).index() - 1] = "";
        $(this).prev().focus();
    }
    var digit = e.key
        .replace(/[۰-۹]/g, function (d) { return "۰۱۲۳۴۵۶۷۸۹".indexOf(d).toString(); })
        .replace(/[٠-٩]/g, function (d) { return "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString(); });
    var temp = "0123456789".indexOf(digit);
    digit = temp !== undefined && temp >= 0 ? temp.toString() : "";
    $(this).val(digit);
    verifyCode[$(this).index()] = digit;
    if (digit.length && $(this).index() < 3)
        $(this).next().focus();
    if (verifyCode.filter(function (e) { return e.length; }).length === 4)
        loginButton.enable();
    else
        loginButton.disable();
});
