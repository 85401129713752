import * as bootstrap from "bootstrap";
var $ = require("jquery");
function headerToolsTrigger() {
    $(document).ready(function () {
        var Node = $("#header-tools-menu")[0].outerHTML;
        var tools = $('[data-bs-toggle="header-legal-tools"]');
        new bootstrap.Popover(tools, {
            html: true,
            trigger: "hover",
            placement: "auto",
            content: function () {
                return Node;
            },
            container: tools,
            offset: "[0, -3]",
        });
        $(document).on("mouseenter", "#header-tools-menu .titles .subtitle", function () {
            var index = $(this).index();
            $("#header-tools-menu .titles .subtitle").removeClass("active");
            $("#header-tools-menu .title-detail").removeClass("active");
            $("#header-tools-menu .titles .subtitle").eq(index).addClass("active");
            $("#header-tools-menu .title-detail").eq(index).addClass("active");
        });
    });
}
headerToolsTrigger();
