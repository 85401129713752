var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Toastify from "toastify-js";
export function toast(message, type, options) {
    var bg = "#ff678c";
    if (type === "success") {
        bg = "#4caf50";
    }
    else if (type === "info") {
        bg = "#29b6f6";
    }
    var config = __assign(__assign({ text: message, duration: 3000, position: "right", stopOnFocus: true }, options), { style: __assign({ background: bg }, options.style) });
    Toastify(config).showToast();
}
