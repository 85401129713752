import "./basicInput.style.scss";
var $ = require("jquery");
var InputBase = /** @class */ (function () {
    function InputBase(selector, config) {
        var _this = this;
        if (config.onClick)
            this._onClick = config.onClick;
        this._elem = $(selector);
        this._mainButton = this._elem.find(".base-input-body");
        this._value = this._mainButton.find("> p");
        this._error = this._elem.find(".error-text");
        this._placeholder = this._elem.attr("placeholder");
        this._mainButton.on("click", function () {
            if (_this._onClick)
                _this._onClick();
        });
    }
    InputBase.prototype.setValue = function (value) {
        this._value.text(value ? value : this._placeholder);
        if (value)
            this._elem.addClass("fill");
        else
            this._elem.removeClass("fill");
    };
    return InputBase;
}());
export default InputBase;
