var $ = require("jquery");
var Button = /** @class */ (function () {
    function Button(selector, onClick) {
        var _this = this;
        this.button = $(selector);
        this.clickHandler = function () {
            if (onClick) {
                var returned = onClick();
                if (returned) {
                    _this.button.append('<div class="spinner-border" role="status" aria-hidden="true"></div>');
                    _this.button.addClass("loading");
                    returned.then(function () {
                        _this.button.remove(".spinner-border");
                        _this.button.removeClass("loading");
                    });
                }
            }
        };
        this.button.click(this.clickHandler);
    }
    Button.prototype.disable = function () {
        this.button.prop("disabled", true);
    };
    Button.prototype.enable = function () {
        this.button.prop("disabled", false);
    };
    return Button;
}());
export default Button;
