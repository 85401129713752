export var registerSavedPhone = {
    key: "TOBEGU_REGISTER_SAVED_PHONE",
    get: function () {
        return localStorage.getItem(registerSavedPhone.key);
    },
    set: function (value) {
        localStorage.setItem(registerSavedPhone.key, value);
    },
    remove: function () {
        localStorage.removeItem(registerSavedPhone.key);
    }
};
export var authToken = {
    key: 'U_CHECK_AUTH_TOKEN',
    get: function () {
        var data = localStorage.getItem(authToken.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    set: function (value) {
        localStorage.setItem(authToken.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(authToken.key);
    },
};
export var userData = {
    key: "TOBEGU_USER",
    get: function () {
        return JSON.parse(localStorage.getItem(userData.key));
    },
    set: function (value) {
        localStorage.setItem(userData.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(userData.key);
    }
};
export var pendingRequest = {
    key: "TOBEGU_PENDING_REQUEST",
    get: function () {
        return JSON.parse(localStorage.getItem(pendingRequest.key));
    },
    set: function (value) {
        localStorage.setItem(pendingRequest.key, JSON.stringify(value));
    },
    remove: function () {
        localStorage.removeItem(pendingRequest.key);
    }
};
