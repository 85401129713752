import * as bootstrap from "bootstrap";
var $ = require("jquery");
function headerServiceTrigger() {
    $(document).ready(function () {
        var sources = $('[data-bs-toggle="header-sources"]');
        var Node = $("#header-sources-menu")[0].outerHTML;
        new bootstrap.Popover(sources, {
            html: true,
            trigger: "hover",
            placement: "auto",
            content: function () {
                return Node;
            },
            container: sources,
            offset: "[0, -3]",
        });
        $(document).on("mouseenter", "#header-sources-menu .titles .subtitle", function () {
            var index = $(this).index();
            $("#header-sources-menu .titles .subtitle").removeClass("active");
            $("#header-sources-menu .title-detail").removeClass("active");
            $("#header-sources-menu .titles .subtitle")
                .eq(index)
                .addClass("active");
            $("#header-sources-menu .title-detail").eq(index).addClass("active");
        });
    });
}
headerServiceTrigger();
