var $ = require("jquery");
export function emailValidation(value) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value.toLowerCase());
}
export function passwordValidation(value) {
    var re = new RegExp("^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return re.test(value);
}
export function urlValidation(value) {
    var re = /[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    return re.test(value);
}
export function usernameValidation(value) {
    var re = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i;
    return re.test(value);
}
export function getValidUrl(url) {
    if (url === void 0) { url = ""; }
    var newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");
    if (/^(:\/\/)/.test(newUrl)) {
        return "http".concat(newUrl);
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return "http://".concat(newUrl);
    }
    return newUrl;
}
export function irPhoneValidator(phone) {
    if (phone.length < 11)
        return false;
    if (phone.slice(0, 2) !== "09" && phone.slice(0, 2) !== "۰۹")
        return false;
    return true;
}
export function checkAuthStatus() {
    return $("body").attr("authStatus") === "valid";
}
