import "./header.style.scss";
import "./sources/sources.index";
import "./tools/tools.index";
import "./login/login.index";
import "./profile/profile.index";
import { oauthObject } from "scripts/storage";
var ENV = require("../../env.json");
var $ = require("jquery");
function oauth() {
    var response = oauthObject.get();
    if (response) {
        authenticateOthers(ENV.user + "/oauth/", response);
        authenticateOthers(ENV.lawyer + "/oauth/", response);
        authenticateOthers(ENV.calculation + "/oauth/", response);
        authenticateOthers(ENV.location + "/oauth/", response);
    }
    oauthObject.remove();
}
var authenticateOthers = function (url, params) {
    var newURL = new URL(url);
    for (var _i = 0, _a = Object.keys(params); _i < _a.length; _i++) {
        var key = _a[_i];
        newURL.searchParams.append(key, params[key]);
    }
    var iframe = document.createElement("iframe");
    iframe.style.width = "0";
    iframe.style.height = "0";
    iframe.style.border = "none";
    iframe.style.margin = "0";
    iframe.style.padding = "0";
    iframe.style.opacity = "0";
    iframe.src = newURL.href;
    document.body.appendChild(iframe);
};
oauth();
