var ENV = require("../env.json");
export var API = {
    account: {
        sendVerify: ENV.auth + "/oauth/token/",
        sendCode: ENV.auth + "/api/account/auth/send_code/",
        proFieldCategories: ENV.api + "account/profield_category/",
        proField: ENV.api + "account/profield/",
        publicLawyer: ENV.api + "account/lawyer/public",
        logout: ENV.auth + "/api/account/user/logout/",
    },
    auth: {
        rotateSession: ENV.api + "auth/rotate_token_to_session/",
    },
    rule: {
        advisoryOpinion: ENV.api + "rule/advisory_opinion/",
        judicialDecision: ENV.api + "rule/judicial_decision/",
        convention: ENV.api + "rule/convention/",
        legalDoctors: ENV.api + "rule/legal_doctrine/",
    },
    QA: {
        questions: ENV.api + "QA/question/",
    },
    judicialFile: {
        buy: ENV.api + "judicial_file/judicial_file/buy_service/",
        public: ENV.api + "judicial_file/judicial_file/buy_public_service/",
        free: function (id) {
            return "".concat(ENV.api, "judicial_file/judicial_file/").concat(id, "/buy_free_service/");
        },
    },
    contract: {
        buy: ENV.api + "contractual_setting/contractual_setting/buy_service/",
        public: ENV.api + "contractual_setting/contractual_setting/buy_public_service/",
        free: function (id) {
            return "".concat(ENV.api, "contractual_setting/contractual_setting/").concat(id, "/buy_free_service/");
        },
    },
    chat: {
        buy: ENV.api + "chat/buy_service/",
    },
    telephoneAdvisory: {
        lawyers: ENV.api + "account/lawyer/public/",
        buy: ENV.api + "telephone_advisory/buy_service/",
    },
    rateAndReview: {
        index: ENV.api + "rate_and_review/",
    },
    core: {
        fileAttachment: ENV.api + "core/file_attachment/",
        test: ENV.api + "content_suggestion/",
    },
};
