// import { toPersionNumber } from "../../interface/string";
import { extractURLParams } from "../../../scripts/scripts";
import "./textarea.style.scss";
var $ = require("jquery");
var TextArea = /** @class */ (function () {
    function TextArea(selector, config) {
        var _this = this;
        this.elem = $(selector);
        this.input = $(selector + " textarea");
        this.icon = $(selector + " i");
        this.error = $(selector + " .error-text");
        this.focusHandler = this.focusHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.input.focus(this.focusHandler);
        this.input.blur(this.blurHandler);
        this.input.on("keydown", function (e) {
            if (e.keyCode !== 8 &&
                config.maxLength !== undefined &&
                _this.getVal().length >= config.maxLength) {
                e.preventDefault();
                e.stopPropagation();
            }
        });
        this.input.on("input", function (e) {
            _this.focusHandler();
            if (config.onChange)
                config.onChange(_this.getVal());
        });
        this.input.on("keyup", function (e) {
            if (config.onEnter && e.keyCode === 13)
                config.onEnter(_this.getVal());
        });
        this.icon.click(function () {
            if (config.onIconClick)
                config.onIconClick(_this.getVal());
        });
        for (var _i = 0, _a = Object.entries(extractURLParams(window.location.href)); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key === "search" && typeof value === "string") {
                this.input.val(value);
            }
        }
        this.elem.click(function () {
            if (!this.input.is(":focus")) {
                this.input.focus();
            }
        }.bind(this));
    }
    TextArea.prototype.focusHandler = function () {
        this.error.addClass("d-none");
        this.elem.removeClass("error");
        this.elem.removeClass("warning");
        this.elem.addClass("fill");
    };
    TextArea.prototype.blurHandler = function () {
        if (!this.input ||
            !this.input.val() ||
            this.input.val().toString().length === 0) {
            this.elem.removeClass("fill");
        }
    };
    TextArea.prototype.showError = function (text) {
        this.elem.addClass("error");
        this.error.removeClass("d-none").text(text);
    };
    TextArea.prototype.getVal = function () {
        var _a;
        return (_a = this.input.val()) === null || _a === void 0 ? void 0 : _a.toString();
    };
    TextArea.prototype.setError = function (text) {
        this.elem.addClass("error");
        this.error.removeClass("d-none").text(text);
    };
    TextArea.prototype.setVal = function (value) {
        this.input.val(value);
    };
    return TextArea;
}());
export default TextArea;
